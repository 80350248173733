@mixin ufl-font--gentona-bold {
  font-family: "gentona-bold", Arial, sans-serif;
}

@mixin ufl-font--gentona {
  font-family: "gentona", Arial, sans-serif;
}

@mixin ufl-font--gentona-light {
  font-family: "gentona-light", Arial, sans-serif;
}

@mixin ufl-font--gentona-normal {
  font-family: "gentona-book", Arial, sans-serif;
}

@mixin ufl-font--quadonmedium {
  font-family: "quadonmedium", Arial, serif;
}

@mixin ufl-font--quadon-thin {
  font-family: "quadon-thin", Arial, serif;
}

@mixin ufl-font--sans {
  @include ufl-font--gentona;
}

@mixin ufl-font--sans-serif {
  @include ufl-font--gentona;
}

@mixin ufl-font--sans--light {
  @include ufl-font--gentona-light;
}

@mixin ufl-font--sans--normal {
  @include ufl-font--gentona-normal;
}

@mixin ufl-font--sans--bold {
  @include ufl-font--gentona-bold;
}

@mixin ufl-font--sans-serif--bold {
  @include ufl-font--gentona-bold;
}

@mixin ufl-font--serif {
  @include ufl-font--quadonmedium;
}

@mixin ufl-font--serif--thin {
  @include ufl-font--quadon-thin;
}

// @font-face {
//     font-family: 'quadonmedium';
//     src: url('/fonts/rene_bieder_-_quadon_medium-webfont.eot');
//     src: url('/fonts/rene_bieder_-_quadon_medium-webfont.eot?#iefix') format('embedded-opentype'),
//          url('/fonts/rene_bieder_-_quadon_medium-webfont.woff') format('woff'),
//          url('/fonts/rene_bieder_-_quadon_medium-webfont.ttf') format('truetype'),
//          url('/fonts/rene_bieder_-_quadon_medium-webfont.svg#quadonmedium') format('svg');
//     font-weight: normal;
//     font-style: normal;
//     font-display: fallback;
// }
@font-face {
  font-family: "BillionDreams";
  src: url("/fonts/BillionDreams.eot");
  src: url("/fonts/BillionDreams.eot?#iefix") format("embedded-opentype"),
    url("/fonts/BillionDreams.woff2") format("woff2"),
    url("/fonts/BillionDreams.woff") format("woff"),
    url("/fonts/BillionDreams.ttf") format("truetype"),
    url("/fonts/BillionDreams.svg#BillionDreams") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Milkshake";
  src: url("/assets/fonts/Mikeshake.otf") format("opentype");
}
@font-face {
  font-family: "gentona";
  // src: url("/fonts/rene_bieder_-_gentona_book-webfont.eot");
  src: url("/fonts/rene_bieder_-_gentona_book-webfont.eot?iefix")
      format("embedded-opentype"),
    url("/fonts/rene_bieder_-_gentona_book-webfont.woff") format("woff"),
    url("/fonts/rene_bieder_-_gentona_book-webfont.ttf") format("truetype"),
    url("/fonts/rene_bieder_-_gentona_book-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-bold";
  src: url("/fonts/rene_bieder_-_gentona_bold-webfont.eot");
  src: url("/fonts/rene_bieder_-_gentona_bold-webfont.eot?iefix")
      format("embedded-opentype"),
    url("/fonts/rene_bieder_-_gentona_bold-webfont.woff") format("woff"),
    url("/fonts/rene_bieder_-_gentona_bold-webfont.ttf") format("truetype"),
    url("/fonts/rene_bieder_-_gentona_bold-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "gentona-light";
  src: url("/fonts/rene_bieder_-_gentona_extralight-webfont.eot");
  src: url("/fonts/rene_bieder_-_gentona_extralight-webfont.eot?iefix")
      format("embedded-opentype"),
    url("/fonts/rene_bieder_-_gentona_extralight-webfont.woff") format("woff"),
    url("/fonts/rene_bieder_-_gentona_extralight-webfont.ttf")
      format("truetype"),
    url("/fonts/rene_bieder_-_gentona_extralight-webfont.svg") format("svg");
  font-weight: thin;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "gentona-book";
  src: url("/fonts/hinted-subset-Gentona-Book.eot");
  src: url("/fonts/hinted-subset-Gentona-Book.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/hinted-subset-Gentona-Book.woff2") format("woff2"),
    url("/fonts/hinted-subset-Gentona-Book.woff") format("woff"),
    url("/fonts/hinted-subset-Gentona-Book.ttf") format("truetype"),
    url("/fonts/hinted-subset-Gentona-Book.svg#Gentona-Book") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "gentona-bold-italic";
  src: url("/fonts/rene_bieder_-_gentona_bold_italic-webfont.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-book-italic";
  src: url("/fonts/rene_bieder_-_gentona_book_italic-webfont.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-extrabold-italic";
  src: url("/fonts/rene_bieder_-_gentona_extrabold_italic-webfont.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-extrabold";
  src: url("/fonts/rene_bieder_-_gentona_extrabold-webfont.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-extralight-italic";
  src: url("/fonts/rene_bieder_-_gentona_extralight_italic-webfont.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-heavy-italic";
  src: url("/fonts/rene_bieder_-_gentona_heavy_italic-webfont.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-heavy";
  src: url("/fonts/rene_bieder_-_gentona_heavy-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-light-italic";
  src: url("/fonts/rene_bieder_-_gentona_light_italic-webfont.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-light";
  src: url("/fonts/rene_bieder_-_gentona_light-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-medium-italic";
  src: url("/fonts/rene_bieder_-_gentona_medium_italic-webfont.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-medium";
  src: url("/fonts/rene_bieder_-_gentona_medium-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-semibold-italic";
  src: url("/fonts/rene_bieder_-_gentona_semibold_italic-webfont.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-semibold";
  src: url("/fonts/rene_bieder_-_gentona_semibold-webfont.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-thin-italic";
  src: url("/fonts/rene_bieder_-_gentona_thin_italic-webfont.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

// @font-face {
//   font-family: "quadon-thin";
//   src: url("/fonts/hinted-subset-Quadon-Thin.eot");
//   src: url("/fonts/hinted-subset-Quadon-Thin.eot?#iefix")
//       format("embedded-opentype"),
//     url("/fonts/hinted-subset-Quadon-Thin.woff2") format("woff2"),
//     url("/fonts/hinted-subset-Quadon-Thin.woff") format("woff"),
//     url("/fonts/hinted-subset-Quadon-Thin.ttf") format("truetype"),
//     url("/fonts/hinted-subset-Quadon-Thin.svg#Quadon-Thin") format("svg");
//   font-weight: 300;
//   font-style: normal;
//   font-display: fallback;
// }

.ufl-font--sans {
  @include ufl-font--sans;
}

.ufl-font--sans-serif {
  @include ufl-font--sans-serif;
}

.ufl-font--sans--light {
  @include ufl-font--sans--light;
}

.ufl-font--sans--normal {
  @include ufl-font--sans--normal;
}

.ufl-font--sans--bold {
  @include ufl-font--sans--bold;
}

.ufl-font--sans-serif--bold {
  @include ufl-font--sans-serif--bold;
}

.ufl-font--serif {
  @include ufl-font--serif;
}

.ufl-font--serif--thin {
  @include ufl-font--serif--thin;
}
