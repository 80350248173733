@import 'styles/branding/fonts';

.navbar-default {
  @include ufl-font--sans;
  background-color: #00529b;
  border: 0;
  transition: 0.5s;

  .logo {
    display: inline-block;
    float: left;
    background-color: #00529b;
    padding: 5px;

    img {
      height: 55px;
      width: 55px;
    }
  }

  .navbar-brand {
    @include ufl-font--sans;
    color: #00529b;
    padding: 24px 0 0 15px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 0;
  }
}

.navbar {
  min-height: 50px;
  margin-bottom: 20px;
  display: block;
}

.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  top: 0;
}

.collapse {
  display: none;
}

.navbar-collapse.in {
  overflow-y: auto;
}

.collapse.in {
  display: block;
}